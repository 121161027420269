import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

export default function useBackLink() {
  const location = useLocation();

  const getBackLink = useCallback(() => {
    const path = `${location.pathname}${location.search}`;
    const encodePath = encodeURIComponent(path);
    return `?back=${encodePath}`;
  }, [location.pathname, location.search]);

  const resolveBackLink = useCallback(() => {
    const { search } = location;
    if (search) {
      const parseRes = qs.parse(search);
      const ds = decodeURIComponent(parseRes['?back'] as string);

      return ds;
    }
    return null;
  }, [location]);

  return { getBackLink, resolveBackLink };
}
