import { DEFAULT_API_PREFIX } from '@/constants/apiPrefixes';
import { get } from '@/utils/requestUtils';

//获取消息列表
export interface NotifyItem {
  id: string;
  content: string;
  createTime: string;
  level: number;
  referId: string;
  status: number;
  title: string;
  type: number;
  areaId: string;
}

// 调用方法
export async function getLatestList() {
  return get<NotifyItem[]>('/notify/getLatestList', {
    prefix: DEFAULT_API_PREFIX
  });
}

//全部已读
// 调用方法
export async function allRead() {
  return get<null>('/notify/allRead', {
    prefix: DEFAULT_API_PREFIX
  });
}

//单个通知已读
// 调用方法
export async function notifyRead(params: { id: string }) {
  return get<null>('/notify/notifyRead/' + params.id, {
    prefix: DEFAULT_API_PREFIX
  });
}
