import { NotifyItem, allRead, getLatestList, notifyRead } from '@/services/notify/notify.service';
import { Badge, Dropdown } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import css from './Notify.module.scss';

interface Props {
  style?: React.CSSProperties;
}

export default function Notify({ style }: Props) {
  const navigate = useNavigate();
  const [list, setList] = useState<NotifyItem[]>([]);

  // 通知类型
  const notifyTypes = [
    { id: 1, name: '充电桩告警' },
    { id: 2, name: '工单' },
    { id: 3, name: '光伏告警' }
  ];

  // 点击查看跳转详情
  const viewDetail = useCallback(
    (data: NotifyItem) => {
      const { type, areaId, referId: id } = data;
      switch (type) {
        case 0:
          navigate('/alarm-management/energy-alarm', { state: { areaId, viewId: id } });
          break;
        case 1:
          navigate('/alarm-management/station-alarm', { state: { viewId: id } });
          break;
        case 2:
          navigate('/alarm-management/worksheet-management', { state: { viewId: id } });
          break;
        case 3:
          navigate('/alarm-management/photovoltaic-alarm', { state: { viewId: id } });
          break;
      }
    },
    [navigate]
  );

  // 获取未读消息通知
  const handleGetLatestList = useCallback(async () => {
    const res = await getLatestList();
    if (res.code === 200) {
      setList(res.result);
    } else {
      setList([]);
    }
  }, []);

  const readNotify = useCallback(
    async (id: string) => {
      const res = await notifyRead({ id });
      if (res.code === 200) {
        handleGetLatestList();
      }
    },
    [handleGetLatestList]
  );

  const readAll = useCallback(async () => {
    const res = await allRead();
    if (res.code === 200) {
      handleGetLatestList();
    }
  }, [handleGetLatestList]);

  useEffect(() => {
    handleGetLatestList();
  }, [handleGetLatestList]);

  // 定时任务，每30秒获取一次消息通知
  useEffect(() => {
    const intId = setInterval(() => {
      handleGetLatestList();
    }, 30000);
    return () => clearInterval(intId);
  }, [handleGetLatestList]);

  return (
    <div style={style} className={css.container}>
      <Dropdown
        placement="bottom"
        dropdownRender={() => {
          return (
            <div className={css.notifyContainer}>
              {list.length > 0 && (
                <div
                  className={css.titleContainer}
                  onClick={() => {
                    readAll();
                  }}
                >
                  全部已读
                </div>
              )}
              {list.length === 0 && <div style={{ textAlign: 'center' }}>暂无数据</div>}
              {list.map(i => {
                return (
                  <div className={css.notifyItem} key={i.id}>
                    <div>
                      <div>{notifyTypes.find(t => t.id === i.type)?.name}</div>
                      <div>等级{i.level}</div>
                      <div>{i.title}</div>
                    </div>
                    <div className={css.viewContainer}>
                      <div
                        className={css.view}
                        onClick={() => {
                          viewDetail(i);
                        }}
                      >
                        查看
                      </div>
                      <div
                        className={css.read}
                        onClick={() => {
                          readNotify(i.id);
                        }}
                      >
                        已读
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        }}
        trigger={['click']}
      >
        <Badge count={list.length} showZero={false} size="small">
          <div className={css.notifyIcon} style={{ backgroundImage: `url('/images/notify.png')` }}></div>
        </Badge>
      </Dropdown>
    </div>
  );
}
