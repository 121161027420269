import _ from 'lodash';

export interface AddUserReq {
  id?: string;
  password: string;
  roleId: string;
  roleName: string;
  username: string;
}

export interface GetUserReq {
  username?: string;
  roleType?: UserType;
  roleName?: string;
  merchantId?: string;
  enterPriseName?: string;
}

export interface UserListItem {
  id: string;
  password: string;
  roleIds: string;
  roleNames: string;
  username: string;
  type: UserType;
  merchantId: string;
  phone: string;
  email: string;
}

export interface AddRoleReq {
  id?: string;
  moduleIds?: string[];
  roleName: string;
}

export interface GetRoleReq {
  roleName?: string;
  merchantId?: string;
  type?: UserType;
  merchantName?: string;
}

export interface RoleListItem {
  id: string;
  moduleIds: string[];
  roleName: string;
  type: UserType;
  merchantId: string;
}

export interface GetRoleResp {
  result: RoleListItem[];
}

export interface DeleteRoleReq {
  id: string;
  moduleIds?: string[];
  roleName?: string;
}

export interface DeleteUserReq {
  id: string;
  moduleIds: string[];
  roleName: string;
}

export interface GetModuleReq {
  name?: string;
}

export interface ModuleInfo {
  name: string;
  route: string;
  icon: string;
  orderIndex: number;
}

export interface ChildrenModule {
  id: string;
  moduleInfo: ModuleInfo;
}

export interface ModuleListItem {
  childrenModules: ChildrenModule[];
  id: string;
  moduleInfo: ModuleInfo;
}

export interface AddModuleReq {
  id: string;
  isRoot: boolean;
  moduleInfo: ModuleInfo;
  parentId: string;
}

export enum UserType {
  'AGGREGATOR' = 1,
  'OPERATOR' = 2
}

export const userTypeMap = {
  [UserType.AGGREGATOR]: '聚合商',
  [UserType.OPERATOR]: '运营商'
};

export const userTypeList = _.map(userTypeMap, (v, k) => ({ label: v, value: Number(k) }));

export interface UserInfo {
  id: string;
  modules: ModuleListItem[];
  username: string;
  roleNames: string[];
  type: UserType;
  merchantId: string;
}

export interface DeleteModuleReq {
  id: string;
  isRoot?: boolean;
  moduleInfo?: ModuleInfo;
  parentId?: string;
}

export enum IndustryType {
  'GUANGFU' = 2,
  'CHUNENG' = 4,
  'NENGHAO' = 3,
  'CHONGDIANZHUANG' = 1
}

export const IndustryTypeMap = {
  [IndustryType.GUANGFU]: '光伏',
  [IndustryType.CHUNENG]: '储能',
  [IndustryType.NENGHAO]: '能耗',
  [IndustryType.CHONGDIANZHUANG]: '充电桩'
};

export const IndustryTypeList = [
  {
    value: 1,
    label: '充电桩'
  },
  {
    value: 2,
    label: '光伏'
  },
  {
    value: 3,
    label: '能耗'
  },
  {
    value: 4,
    label: '储能'
  }
];
// 根据values获取name
export const getIndustryName = (Industrys: number[]) => {
  const name: string[] = [];
  if (Industrys) {
    Industrys.map((Industry: number) => {
      name.push(IndustryTypeList.filter(item => item.value == Industry)[0].label);
    });
  }
  return name.join(', ');
};
