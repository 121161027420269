export enum AreaType {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3
}

export enum SensorType {
  ELECTRICITY = 1,
  WATER = 2,
  GAS = 3
}

export interface AreaTreeNode {
  id: string;
  name: string;
  type: AreaType;
  children: AreaTreeNode[];
}

export interface FetchAreaTreeResp {
  tree: AreaTreeNode[];
}

export interface FetchDetailInfoReq {
  areaType: AreaType;
  id: string;
}

export interface FetchDetailInfoResp {
  ampere: number;
  kwh: number;
  name: string;
  power: number;
  voltage: number;
  volume: number;
  weight: number;
}

export interface FetchNextAreaInfoReq {
  id: string;
  sensorType: SensorType;
  areaType: AreaType;
}

export interface NextAreaInfoItem {
  areaId: string;
  data: number;
  name: string;
}

export interface FetchNextAreaInfoResp {
  midLeft: NextAreaInfoItem[];
}

export interface FetchUsageDetailInfoReq {
  id: string;
  areaType: AreaType;
  sensorType: SensorType;
  startDate?: string;
  endDate?: string;
  pageNo: number;
  pageSize: number;
  date?: string;
}

export interface UsageInfoItem {
  ampere: number;
  kwh: number;
  power: number;
  voltage: number;
  volume: number;
  weight: number;
  recordTime: string;
}

export interface FetchUsageDetailInfoResp {
  bottom: {
    current: number;
    pages: number;
    size: number;
    total: number;
    records: UsageInfoItem[];
  };
}

export interface AreasListItem {
  areaType: number;
  id: string;
}
export interface MidRightRequest {
  areas?: AreasListItem[];
  calendarType?: number;
  dataType?: number;
  endDate?: string;
  showType?: number;
  startDate?: string;
}

export interface MidRightListItem {
  data: number;
  time: string;
  name: string;
}
export interface MidRightResponse {
  midRight: MidRightListItem[][];
}
//下级区域数据类型
export interface getSubAreaElectricityType {
  createTime: string;
  deleted: number;
  electricity: 0;
  id: string;
  merchantId: string;
  name: string;
  parentId: string;
  path: string | null;
  sort: number;
  type: number;
  updateTime: string | null;
}
export interface getAreaUsageDistributionType {
  time: string;
  value: number;
}
export interface getItemListByAreaIdAndSensorTypeInterface {
  createTime: string;
  dataType: number;
  deleted: number;
  display: number;
  fix: number;
  id: string;
  key: string;
  name: string;
  recordTime: string;
  sensorId: string;
  sortNo: number;
  templateId: string | null;
  unit: string;
}
export interface getAreaRealElectricityConditionType {
  electricCurrent: number;
  electricity: number;
  power: number;
  time: string;
  voltage: number;
  name: string;
  p: number;
  q: number;
  ia: number;
  ib: number;
  ic: number;
  ua: number;
  ub: number;
  uc: number;
  epin: number;
}
export interface fetchUsageDetailInfoType {
  activePower: number;
  apparentPower: number;
  electricCurrent: number;
  frequency: number;
  id: string;
  instrumentNumber: string;
  powerFactor: number;
  recordTime: string;
  sensorId: string;
  totalActiveEnergy: number;
  totalActiveFlatEnergy: number | null;
  totalActivePeakEnergy: number | null;
  totalActiveTopEnergy: number | null;
  totalActiveValleyEnergy: number | null;
  voltage: number;
}
export interface areaTreeNodeType {
  key: string;
  title: string;
}
export interface areaTreeDataType {
  key: string;
  title: string;
  children: areaTreeDataType[];
}
