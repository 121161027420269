import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface AlarmListSliceState {
  merchantId?: any;

  cpvMerchantId?: any;
}

export const alarmListSlice = createSlice({
  name: 'alarmListSlice',
  initialState: {
    merchantId: "",
    cpvMerchantId: ""
  } as AlarmListSliceState,
  reducers: {
    // updateBaseEnergyPriceData: (state, action: PayloadAction<{ baseEnergyPriceData: ElectricPriceBaseRecordItem; }>) => {
    //   state.baseEnergyPriceData = action.payload;
    // },
    updateMerchantId: (state, action: PayloadAction<string>) => {
      state.merchantId = action.payload;
    },
    updateCpvMerchantId: (state, action: PayloadAction<string>) => {
      state.cpvMerchantId = action.payload;
    },

  }
});

// Action creators are generated for each case reducer function
export const { updateMerchantId,updateCpvMerchantId } = alarmListSlice.actions;

export default alarmListSlice.reducer;
