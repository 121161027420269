import React, { ComponentType, ForwardRefExoticComponent, SVGProps, memo, useMemo, useCallback, Suspense } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import css from './NormalLayout.module.scss';
import Icon from '@ant-design/icons';
// import { ReactComponent as logo } from '@/pages/User/Login/logo.svg';
import { ReactComponent as nenghao } from '@/icons/meau-normal/nenghao.svg';
import { ReactComponent as nenghaoActive } from '@/icons/mixed-select/nenghao.svg';
import { NavLink } from 'react-router-dom';
import { Dropdown, MenuProps, Spin } from 'antd';
import { ReactComponent as DownDropSVG } from '@/icons/border/downdrop.svg';
import EmsNavLink from '@/component/EmsNavLink/EmsNavLink';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { useAppSelector } from '@/hooks/reduxHooks';
import _ from 'lodash';
import { useRequest } from 'ahooks';
import { logout } from '@/services/systemSetting/systemSetting.service';
import Notify from '@/component/Notify/Notify';
import IEMSLogo from '@/pages/User/Login/IEMS-logo.png';

const icon: {
  [key: string]:
    | ComponentType<CustomIconComponentProps | SVGProps<SVGSVGElement>>
    | ForwardRefExoticComponent<CustomIconComponentProps>;
} = { nenghao: nenghao, nenghaoActive: nenghaoActive };
const NormalLayout = memo(() => {
  const navigate = useNavigate();
  const { userInfo } = useAppSelector(state => ({
    userInfo: state.system.userInfo
  }));
  const sortedModules = useMemo(() => _.orderBy(userInfo?.modules ?? [], 'moduleInfo.orderIndex'), [userInfo?.modules]);

  const logoutFunc = useRequest(logout, {
    manual: true,
    onSuccess: res => {
      if (res.code === 200) {
        localStorage.removeItem('token');
        navigate('/user/login');
      }
    }
  });

  const handleLogout = useCallback(() => {
    logoutFunc.run();
  }, [logoutFunc]);
  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === '3') {
      handleLogout();
    }
  };
  const items: MenuProps['items'] = [
    // {
    //   key: '1',
    //   label: (
    //     <NavLink to="" className="highlight">
    //       <span className={css.item}>个人设置</span>
    //     </NavLink>
    //   )
    // },
    // {
    //   key: '2',
    //   label: (
    //     <NavLink to="sensor-settings" className="highlight">
    //       <span className={css.item}>传感器设置</span>
    //     </NavLink>
    //   )
    // },
    {
      key: '3',
      label: (
        <span className={css.item} onClick={handleLogout}>
          退出登录
        </span>
      )
    }
  ];
  return (
    <div className={css.NormalLayout}>
      <div className={css.header}>
        <div className={css.logo}>
          {/* <Icon style={{ fontSize: 24, marginRight: 2 }} component={logo} />
          <div className={css.name}></div> */}
          <img src={IEMSLogo} alt="" height={36} />
        </div>
        <div className={css.menu}>
          <nav>
            {sortedModules.map(item => (
              <EmsNavLink
                key={item.id}
                to={item.moduleInfo?.route}
                icon={icon[item.moduleInfo?.icon.split('/')[0]]}
                activeIcon={icon[item.moduleInfo?.icon.split('/')[1]]}
                title={item.moduleInfo?.name}
              />
            ))}
          </nav>
        </div>
        <div className={css.userCenter}>
          <Notify style={{ marginRight: 20 }} />
          <Dropdown trigger={['click']} menu={{ items, onClick }} overlayClassName="header-dropdown">
            <NavLink to="realtime-monitoring" className="realtime-monitoring-nav-button">
              <span className={css.item}>
                {userInfo.username}
                <Icon
                  style={{ fontSize: 16, marginLeft: 4 }}
                  className="realtime-monitoring-nav-button-icon"
                  component={DownDropSVG}
                />
              </span>
            </NavLink>
          </Dropdown>
        </div>
      </div>
      <div className={css.layoutContent}>
        <Suspense fallback={<Spin spinning className={css.loading} />}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  );
});

export default NormalLayout;
