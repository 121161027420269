import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {UserInfo} from '@/services/systemSetting/type';

interface SystemState {
  userInfo: UserInfo;
  userToken: string;
}

/**
 * 全局用户信息部分
 */



export const systemSlice = createSlice({
  name: "systemInfo",
  initialState: {
    userInfo: {} as UserInfo,
    
    userToken: "",
  } as SystemState,
  reducers: {
    updateUserInfo: (state, action: PayloadAction<UserInfo>) => {
      state.userInfo = action.payload;
    },
    updateUserToken: (state, action: PayloadAction<string>) => {
      state.userToken = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateUserInfo, updateUserToken } = systemSlice.actions;

export default systemSlice.reducer;
