import { configureStore } from '@reduxjs/toolkit';
import systemReducer from './systemSlice';
import realtimeDetailReducer from './realtimeDetailSlice';
import baseEnergyPriceReducer from './baseEnergyPriceSlice';

import alarmListSliceReducer from './alarmListSlice';

const store = configureStore({
  reducer: {
    system: systemReducer,
    realtimeDetail: realtimeDetailReducer,
    baseEnergyPrice: baseEnergyPriceReducer,
    alarmListSlice: alarmListSliceReducer
  }
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
