import { DEFAULT_API_PREFIX } from '@/constants/apiPrefixes';
import { IndustryType } from '@/services/systemSetting/type';
import { get, post } from '@/utils/requestUtils';
import { addType, backType, editType, fetchOperatorListReq, operatorListType } from './type';

//运营商分页列表
export async function getList(params: {
  pageNo: number;
  pageSize: number;
  name?: string;
  unifiedSocialCreditIdentifier?: string;
  industry?: IndustryType;
}) {
  return get<backType>('merchant/page', {
    prefix: DEFAULT_API_PREFIX,
    query: params
  });
}
// 新增运营商
export async function insertOperator(params: addType) {
  return post<null>('merchant/save', {
    prefix: DEFAULT_API_PREFIX,
    data: params
  });
}
//运营商列表
export async function operatorList(params?: fetchOperatorListReq) {
  return get<operatorListType[]>('merchant/queryList', {
    prefix: DEFAULT_API_PREFIX,
    query: params
  });
}
//导出运营商列表
export async function exportOperatorList(params: {
  name?: string;
  unifiedSocialCreditIdentifier?: string;
  industry?: IndustryType;
}) {
  return get<any>('merchant/export', {
    prefix: DEFAULT_API_PREFIX,
    query: params
  });
}
//删除运营商
export async function deleteOperator(params: { id: string }) {
  return get<null>('merchant/delete', {
    prefix: DEFAULT_API_PREFIX,
    query: params
  });
}
// 编辑运营商
export async function editOperator(params: editType) {
  return post<null>('merchant/edit', {
    prefix: DEFAULT_API_PREFIX,
    data: params
  });
}
//获取运营商信息
export async function detailOperator(params: { id: string }) {
  return get<operatorListType>('merchant/queryById', {
    prefix: DEFAULT_API_PREFIX,
    query: params
  });
}
//添加运营商分成 merchantProfit
export async function addOperatorProfit(params: any) {
  return post<null>('merchantProfit/add', {
    prefix: DEFAULT_API_PREFIX,
    data: params
  });
}
//运营商分成列表
export async function operatorProfitList(params: any) {
  return get<any>('merchantProfit/list', {
    prefix: DEFAULT_API_PREFIX,
    query: params
  });
}
