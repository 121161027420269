import { get, post } from '@/utils/requestUtils';
import { DEFAULT_API_PREFIX } from '@/constants/apiPrefixes';
import {
  AddUserReq,
  AddRoleReq,
  GetRoleReq,
  RoleListItem,
  DeleteRoleReq,
  GetUserReq,
  UserListItem,
  DeleteUserReq,
  GetModuleReq,
  ModuleListItem,
  AddModuleReq,
  UserInfo,
  DeleteModuleReq
} from './type';

export async function addUser(params: AddUserReq) {
  return post('user/add', {
    prefix: DEFAULT_API_PREFIX,
    data: params
  });
}

export async function getUser(params: GetUserReq = {}) {
  return post<UserListItem[]>('user/list', {
    prefix: DEFAULT_API_PREFIX,
    data: params
  });
}

export async function updateUser(params: AddUserReq) {
  return post('user/update', {
    prefix: DEFAULT_API_PREFIX,
    data: params
  });
}

export async function getUserInfo() {
  return get<UserInfo>('user/info', {
    prefix: DEFAULT_API_PREFIX,
    query: {}
  });
}

export async function addRole(params: AddRoleReq) {
  return post('role/add', {
    prefix: DEFAULT_API_PREFIX,
    data: params
  });
}

export async function getRole(params: GetRoleReq) {
  return get<RoleListItem[]>('role/list', {
    prefix: DEFAULT_API_PREFIX,
    query: params
  });
}
export async function getRoleList(params: GetRoleReq) {
  return get<RoleListItem[]>('role/listByType', {
    prefix: DEFAULT_API_PREFIX,
    query: params
  });
}

export async function updateRole(params: AddRoleReq) {
  return post('role/update', {
    prefix: DEFAULT_API_PREFIX,
    data: params
  });
}

export async function deleteRole(params: DeleteRoleReq) {
  return post('role/delete', {
    prefix: DEFAULT_API_PREFIX,
    data: params
  });
}

export async function deleteUser(params: DeleteUserReq) {
  return post('user/delete', {
    prefix: DEFAULT_API_PREFIX,
    data: params
  });
}

export async function getModule(params: GetModuleReq) {
  return get<ModuleListItem[]>('module/list', {
    prefix: DEFAULT_API_PREFIX,
    query: params
  });
}

export async function addModule(params: AddModuleReq) {
  return post('module/add', {
    prefix: DEFAULT_API_PREFIX,
    data: params
  });
}

export async function updateModule(params: AddModuleReq) {
  return post('module/update', {
    prefix: DEFAULT_API_PREFIX,
    data: params
  });
}

export async function deleteModule(params: DeleteModuleReq) {
  return post('module/delete', {
    prefix: DEFAULT_API_PREFIX,
    data: params
  });
}

export async function logout() {
  return post('login/logout', {
    prefix: DEFAULT_API_PREFIX
  });
}
