import NormalLayout from '@/layouts/NormalLayout/NormalLayout';
import colorVariables from '@/styles/colors.scss';
import 'antd/dist/reset.css';
import zhCN from 'antd/lib/locale/zh_CN';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.scss';
import Login from './pages/User/Login/Login';
// import variables from "@/styles/variables.scss";
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import { lazy, useCallback, useMemo } from 'react';
import { useAppSelector } from './hooks/reduxHooks';

const RegionalManagement = lazy(() => import('./pages/EnergyMonitoring/RegionalManagement/RegionalManagement'));
const EnergyMonitoringLayout = lazy(() => import('./layouts/EnergyMonitoringLayout/EnergyMonitoringLayout'));
const DeviceStructure = lazy(() => import('./pages/EnergyMonitoring/DeviceManage/DeviceStructure/DeviceStructure'));
const RealMeter = lazy(() => import('./pages/EnergyMonitoring/DeviceManage/RealMeter/RealMeter'));
const Sensor = lazy(() => import('./pages/EnergyMonitoring/DeviceManage/Sensor/Sensor'));
const OperatorManagement = lazy(() => import('./pages/EnergyMonitoring/OperatorManagement/OperatorManagement'));
const RealtimeDetail = lazy(() => import('./pages/EnergyMonitoring/RealtimeMonitoring/RealtimeDetail/RealtimeDetail'));
const RealtimeOverview = lazy(
  () => import('./pages/EnergyMonitoring/RealtimeMonitoring/RealtimeOverview/RealtimeOverview')
);
// 数据详情
const AddBaseEnergyPriceManagement = lazy(
  () =>
    import(
      './pages/EnergyMonitoring/EnergyConsumptionStatement/AddBaseEnergyPriceManagement/AddBaseEnergyPriceManagement'
    )
);
const AddForeBoneEnergyPrice = lazy(
  () => import('./pages/EnergyMonitoring/EnergyConsumptionStatement/AddForceBoneEnergyPrice/AddForceBoneEnergyPrice')
);
const EditBaseEnergyPriceManagement = lazy(
  () =>
    import(
      './pages/EnergyMonitoring/EnergyConsumptionStatement/EditBaseEnergyPriceManagement/EditBaseEnergyPriceManagement'
    )
);
const EnergyPriceManagement = lazy(
  () => import('./pages/EnergyMonitoring/EnergyConsumptionStatement/EnergyPriceManagement/EnergyPriceManagement')
);
const DataDetail = lazy(() => import('./pages/EnergyMonitoring/RealtimeMonitoring/DataDetail/DataDetail'));

const CheckForceBoneEnergyPrice = lazy(
  () =>
    import('./pages/EnergyMonitoring/EnergyConsumptionStatement/CheckForceBoneEnergyPrice/CheckForceBoneEnergyPrice')
);
const EditForceBoneEnergyPrice = lazy(
  () => import('./pages/EnergyMonitoring/EnergyConsumptionStatement/EditForceBoneEnergyPrice/EditForceBoneEnergyPrice')
);
const AddUsageEnergyPriceManagement = lazy(
  () =>
    import(
      './pages/EnergyMonitoring/EnergyConsumptionStatement/AddUsageEnergyPriceManagement/AddUsageEnergyPriceManagement'
    )
);
const CheckUsageEnergyPriceManagement = lazy(
  () =>
    import(
      './pages/EnergyMonitoring/EnergyConsumptionStatement/CheckUsageEnergyPriceManagement/CheckUsageEnergyPriceManagement'
    )
);
const EditUsageEnergyPriceManagement = lazy(
  () =>
    import(
      './pages/EnergyMonitoring/EnergyConsumptionStatement/EditUsageEnergyPriceManagement/EditUsageEnergyPriceManagement'
    )
);

const ChargingPileScreen = lazy(() => import('./pages/ScreenMonitoring/ChargingPileScreen/ChargingPileScreen'));
const ComprehensiveScreen = lazy(() => import('./pages/ScreenMonitoring/ComprehensiveScreen/ComprehensiveScreen'));
const EnergyStorageScreen = lazy(() => import('./pages/ScreenMonitoring/EnergyStorageScreen/EnergyStorageScreen'));
const PhotovoltaicScreen = lazy(() => import('./pages/ScreenMonitoring/PhotovoltaicScreen/PhotovoltaicScreen'));
//能耗
const DeviceData = lazy(() => import('./pages/EnergyMonitoring/RealtimeMonitoring/DeviceData/DataDetail'));
const DeviceDetail = lazy(() => import('./pages/EnergyMonitoring/RealtimeMonitoring/DeviceDetail/RealtimeDetail'));
const DeviceOverview = lazy(
  () => import('./pages/EnergyMonitoring/RealtimeMonitoring/DeviceOverview/RealtimeOverview')
);
//--电能质量
const EnergyQuantityDetail = lazy(
  () => import('./pages/EnergyMonitoring/EnergyQuantity/EnergyQuantityDetail/EnergyQuantityDetail')
);
const EnergyQuantityDeviation = lazy(
  () => import('./pages/EnergyMonitoring/EnergyQuantity/EnergyQuantityDeviation/EnergyQuantityDeviation')
);
const EnergyQuantityOverview = lazy(
  () => import('./pages/EnergyMonitoring/EnergyQuantity/EnergyQuantityOverview/EnergyQuantityOverview')
);

// 系统

const AppContainer = lazy(() => import('./component/AppContainer/AppContainer'));
const SensorSettings = lazy(() => import('./pages/SensorSettings/SensorSettings'));
const MenuManage = lazy(() => import('./pages/SystemSetting/MenuManage/MenuManage'));
const OperationManagement = lazy(() => import('./pages/SystemSetting/OperationalManagement/OperationalManagement'));
const RoleManage = lazy(() => import('./pages/SystemSetting/RoleManage/RoleManage'));
const UserManage = lazy(() => import('./pages/SystemSetting/UserManage/UserManage'));
// 充电桩管理
const ChargingGunManagement = lazy(() => import('./pages/ChargingStation/ChargingGunManagement/ChargingGunManagement'));
const ChargingLocationManagement = lazy(
  () => import('./pages/ChargingStation/ChargingLocationManagement/ChargingLocationManagement')
);
const ChargingStationManagement = lazy(
  () => import('./pages/ChargingStation/ChargingStationManagement/ChargingStationManagement')
);
const ElectronicCardManagement = lazy(
  () => import('./pages/ChargingStation/ElectronicCardManagement/ElectronicCardManagement')
);
const OperationalManagement = lazy(() => import('./pages/ChargingStation/OperationalManagement/OperationalManagement'));
const PriceManagement = lazy(() => import('./pages/ChargingStation/PriceManagement/PriceManagement'));
//告警管理
const AlarmSetting = lazy(() => import('./pages/AlarmManagement/AlarmSetting/AlarmSetting'));
const EnergyAlarm = lazy(() => import('./pages/AlarmManagement/EnergyAlarm/EnergyAlarm'));
const PhotovoltaicAlarm = lazy(() => import('./pages/AlarmManagement/PhotovoltaicAlarm/PhotovoltaicAlarm'));
const AggregationStationAlarm = lazy(() => import('./pages/AlarmManagement/StationAlarm/Aggregation/StationAlarm'));
const OperatorStationAlarm = lazy(() => import('./pages/AlarmManagement/StationAlarm/Operator/StationAlarm'));
const StorageAlarm = lazy(() => import('./pages/AlarmManagement/StorageAlarm/StorageAlarm'));
const WorksheetManagement = lazy(() => import('./pages/AlarmManagement/WorksheetManagement/WorksheetManagement'));
const ChargingOrderManagement = lazy(
  () => import('./pages/ChargingStation/ChargingOrderManagement/ChargingOrderManagement')
);
const FloorlockManagement = lazy(() => import('./pages/ChargingStation/FloorlockManagement/FloorlockManagement'));
const TicketManagement = lazy(() => import('./pages/ChargingStation/TicketManagement/TicketManagement'));
//数据分析
const ChargingStationAnalysis = lazy(
  () => import('./pages/DataAnalysis/ChargingStationAnalysis/ChargingStationAnalysis')
);
const EnergyAnalysis = lazy(() => import('./pages/DataAnalysis/EnergyAnalysis/EnergyAnalysis'));
const InverterStationReport = lazy(
  () => import('./pages/DataAnalysis/PhotovoltaicAnalysis/InverterStationReport/PhotovoltaicAnalysis')
);
const PowerStationReport = lazy(
  () => import('./pages/DataAnalysis/PhotovoltaicAnalysis/PowerStationReport/PhotovoltaicAnalysis')
);
const PhotovoltaicReport = lazy(
  () => import('./pages/DataAnalysis/StorageAnalysis/PhotovoltaicReport/PhotovoltaicReport')
);
const StorageReport = lazy(() => import('./pages/DataAnalysis/StorageAnalysis/StorageReport/StorageReport'));
//首页
const ChargingStationDashboard = lazy(
  () => import('./pages/Dashboard/ChargingStationDashboard/ChargingStationDashboard')
);
const EnergyDashboard = lazy(() => import('./pages/Dashboard/EnergyDashboard/EnergyDashboard'));
const PhotovoltaicDashboard = lazy(() => import('./pages/Dashboard/PhotovoltaicDashboard/PhotovoltaicDashboard'));
const StorageDashboard = lazy(() => import('./pages/Dashboard/StorageDashboard/StorageDashboard'));
const DistributingManage = lazy(() => import('./pages/EnergyMonitoring/DistributingManage/DistributingManage'));
//光伏管理
const DeviceManagement = lazy(() => import('./pages/PhotovoltaicManagement/DeviceManagement/DeviceManagement'));
const PhotovoltaicOperatorManagement = lazy(
  () => import('./pages/PhotovoltaicManagement/OperatorManagement/OperatorManagement')
);
const PowerStationManagement = lazy(
  () => import('./pages/PhotovoltaicManagement/PowerStationManagement/PowerStationManagement')
);
// import OperatorPowerStationStatus from './pages/PhotovoltaicManagement/OperatorPowerStationStatus/PowerStationStatus';
const ChargeStationAlarmList = lazy(
  () => import('./pages/AlarmManagement/ChargeStationAlarmList/ChargeStationAlarmList')
);
const EnergyAlarmOperator = lazy(() => import('./pages/AlarmManagement/EnergyAlarm/EnergyAlarmOperator'));
const EnergyAlarmSetting = lazy(() => import('./pages/AlarmManagement/EnergyAlarm/EnergyAlarmSetting'));
const CentralizedMeterReading = lazy(() => import('./pages/DataAnalysis/EnergyAnalysis/CentralizedMeterReading'));
const EnergyReport = lazy(() => import('./pages/DataAnalysis/EnergyAnalysis/EnergyReport'));
const LossAnalysis = lazy(() => import('./pages/DataAnalysis/EnergyAnalysis/LossAnalysis'));
const CompareAnalysis = lazy(() => import('./pages/DataAnalysis/EnergyAnalysis/CompareAnalysis'));
const QOQAnalysis = lazy(() => import('./pages/DataAnalysis/EnergyAnalysis/QOQAnalysis'));

const UsageAnalysis = lazy(() => import('./pages/DataAnalysis/EnergyAnalysis/UsageAnalysis'));
const UsageRanking = lazy(() => import('./pages/DataAnalysis/EnergyAnalysis/UsageTanking'));
const UsageTrend = lazy(() => import('./pages/DataAnalysis/EnergyAnalysis/UsageTrend'));
const YOYAnalysis = lazy(() => import('./pages/DataAnalysis/EnergyAnalysis/YOYAnalysis'));

const PointDiagram = lazy(() => import('./pages/EnergyMonitoring/DistributingManage/PointDiagram'));
const PointDiagramEdit = lazy(() => import('./pages/EnergyMonitoring/DistributingManage/PointDiagramEdit'));
const EnergyQuantitySetting = lazy(
  () => import('./pages/EnergyMonitoring/EnergyQuantity/EnergyQuantitySetting/EnergyQuantitySetting')
);
const FrequencyDeviationData = lazy(
  () => import('./pages/EnergyMonitoring/EnergyQuantity/FrequencyDeviationData/FrequencyDeviationData')
);
const ThreePhaseData = lazy(() => import('./pages/EnergyMonitoring/EnergyQuantity/ThreePhaseData/ThreePhaseData'));

const EnergyPowerStationManagement = lazy(
  () => import('./pages/EnergyStorageManagement/PowerStationManagement/PowerStationManagement')
);
const EnergyPowerStationStatus = lazy(
  () => import('./pages/EnergyStorageManagement/PowerStationStatus/PowerStationStatus')
);
const HotAreaDisplayTest = lazy(() => import('./pages/HotAreaDisplayTest/HotAreaDisplayTest'));
const HotAreaTest = lazy(() => import('./pages/HotAreaTest/HotAreaTest'));
const AggregatorPowerStationStatus = lazy(
  () => import('./pages/PhotovoltaicManagement/AggregatorPowerStationStatus/PowerStationStatus')
);

const DistributingDataManage = lazy(
  () => import('./pages/ScreenMonitoring/DistributingDataManage/DistributingDataManage')
);
const DistributingScreen = lazy(() => import('./pages/ScreenMonitoring/DistributingScreen/DistributingScreen'));
const EnergyConsumeScreen = lazy(() => import('./pages/ScreenMonitoring/EnergyConsumeScreen/EnergyConsumeScreen'));

dayjs.locale('zh-cn'); // 使用本地化语言

function App() {
  const { userInfo } = useAppSelector(state => ({
    userInfo: state.system.userInfo
  }));
  const { merchantId, cpvMerchantId } = useAppSelector(state => ({
    merchantId: state.alarmListSlice.merchantId,
    cpvMerchantId: state.alarmListSlice.cpvMerchantId
  }));
  const modules = useMemo(() => userInfo.modules, [userInfo.modules]);

  const activeMenu = useCallback(
    (route: string) => {
      const module = modules?.find(item => item.moduleInfo?.route === route);
      if (module) {
        const childrenModules = module.childrenModules.filter(item => item.moduleInfo.route.split('/').length <= 3);
        const temp = childrenModules.slice(0).sort((a, b) => a.moduleInfo.orderIndex - b.moduleInfo.orderIndex);
        const childrenModule = temp?.[0];
        return childrenModule?.moduleInfo.route || route;
      } else {
        return route;
      }
    },
    [modules]
  );
  return (
    <ConfigProvider
      locale={zhCN}
      autoInsertSpaceInButton={false}
      theme={{
        token: {
          colorTextBase: colorVariables.whiteTitle,
          colorPrimary: colorVariables.primary_7,
          borderRadius: 4,
          borderRadiusLG: 8,
          fontFamily:
            "'HarmonyOS Sans SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'"
        },
        components: {
          Button: {
            colorPrimaryActive: colorVariables.primary_5,
            colorPrimaryHover: colorVariables.primary_6,
            colorTextDisabled: colorVariables.whiteDisable,
            colorBgContainerDisabled: '#444A63'
          },
          Modal: {
            colorBgElevated: colorVariables.backgroundCardBg,
            colorText: colorVariables.whiteTitle,
            colorTextHeading: colorVariables.whiteTitle,
            paddingContentHorizontalLG: 0,
            paddingMD: 0,
            colorTextDescription: colorVariables.whiteSecondaryText,
            colorIconHover: colorVariables.whiteTitle
            // colorTextTertiary: colorVariables.whiteSecondaryText
          },
          Table: {
            colorBorderSecondary: colorVariables.whiteDividers,
            colorFillAlter: colorVariables.whiteTableHeader,
            colorBgContainer: colorVariables.transparent,
            colorTextHeading: colorVariables.whiteTitle,
            colorText: colorVariables.whitePrimaryText,
            fontWeightStrong: 500
          },
          Cascader: {
            controlItemBgActive: colorVariables.backgroundCardBg
          },
          Pagination: {
            colorText: colorVariables.whitePrimaryText,
            colorBgContainer: colorVariables.whiteCard,
            colorTextDisabled: colorVariables.whitePrimaryText
            // colorBorder: colorVariables.whiteBorder,
          },
          Select: {
            colorBgContainer: colorVariables.whiteCard,
            colorText: colorVariables.whitePrimaryText,
            colorTextPlaceholder: colorVariables.whiteSecondaryText,
            colorBorder: colorVariables.whiteBorder,
            colorBgElevated: colorVariables.backgroundPopoverBg,
            controlItemBgActive: colorVariables.whiteHover,
            colorTextQuaternary: colorVariables.whitePrimaryText
          },
          TreeSelect: {
            colorBgContainer: colorVariables.whiteCard,
            colorText: colorVariables.whitePrimaryText,
            colorTextPlaceholder: colorVariables.whiteSecondaryText,
            colorBorder: colorVariables.whiteBorder,
            colorBgElevated: colorVariables.backgroundPopoverBg,
            controlItemBgActive: colorVariables.whiteHover,
            colorTextQuaternary: colorVariables.whitePrimaryText
          },
          Popover: {
            // colorPrimaryActive: colorVariables.primary_5,
            // colorPrimaryHover: colorVariables.primary_6,
            // colorTextDisabled: colorVariables.whiteDisable,
            // colorBgContainerDisabled: '#444A63',
            colorBgElevated: colorVariables.backgroundPopoverBg
          },
          Dropdown: {
            controlItemBgHover: colorVariables.whiteHover,
            colorText: colorVariables.whitePrimaryText,
            colorBgElevated: colorVariables.backgroundPopoverBg
          },
          Radio: {
            colorBgContainer: colorVariables.whiteCard,
            colorBorder: colorVariables.whiteBorder
          },
          Tabs: {
            colorText: colorVariables.whiteTitle
          },
          Anchor: {
            colorText: colorVariables.whiteSecondaryText,
            colorPrimary: colorVariables.whiteTitle,
            colorSplit: colorVariables.whiteDividers
          },
          Tree: {
            colorBgContainer: colorVariables.backgroundCardBg,
            colorText: colorVariables.whiteTitle,
            controlItemBgActive: colorVariables.primary_4
          },
          DatePicker: {
            colorBgContainer: colorVariables.whiteCard,
            colorBorder: colorVariables.whiteBorder,
            colorSplit: colorVariables.whiteDividers,
            colorText: colorVariables.whiteTitle,
            colorTextHeading: colorVariables.whiteTitle,
            colorTextPlaceholder: colorVariables.whiteSecondaryText,
            controlOutline: colorVariables.whiteBorder,
            colorIcon: colorVariables.whiteSecondaryText,
            colorTextDisabled: colorVariables.whiteSecondaryText,
            colorBgElevated: colorVariables.backgroundPopoverBg
          },
          Input: {
            colorText: colorVariables.whiteTitle,
            colorBgContainer: colorVariables.whiteCard,
            colorBorder: colorVariables.whiteBorder,
            controlHeight: 32
          },
          InputNumber: {
            colorText: colorVariables.whiteTitle,
            colorBgContainer: colorVariables.whiteCard,
            colorBorder: colorVariables.whiteBorder,
            controlHeight: 32
          },
          Descriptions: {
            colorSplit: colorVariables.whiteBorder,
            borderRadiusLG: 0
          },
          Checkbox: {
            colorBgContainer: colorVariables.transparent,
            colorBorder: colorVariables.whiteBorder
          },
          Alert: {
            colorErrorBg: '#ffccc7',
            colorText: '#ff4d4f'
          }
        }
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/hotarea-test" element={<HotAreaTest />} />
          <Route path="/hotarea-display-test" element={<HotAreaDisplayTest />} />
          <Route path="/" element={<AppContainer />}>
            {/* 能耗监控 */}
            <Route path="/" element={<NormalLayout />}>
              <Route
                index
                element={<Navigate to="/energy-monitoring/regional-management" />} // 重定向到 能耗监控 - 区域管理
              />
              <Route
                path="energy-monitoring" // 能耗监控
                element={<EnergyMonitoringLayout parentPath="/energy-monitoring" key="energy-monitoring" />}
              >
                <Route
                  index
                  element={<Navigate to={activeMenu('/energy-monitoring')} />} // 重定向到 能耗监控 - 区域管理
                />
                {/* 能耗监控 - 聚合商角色
                运营商管理 */}
                <Route path="operator-management" element={<OperatorManagement />} />
                <Route path="device-manage">
                  <Route
                    index
                    element={<Navigate to="device-structure" />} // 重定向到 能耗监控 - 实时监控 - 实时总览
                  />
                  {/* 能耗监控 - 聚合商角色
                设备架构 */}
                  <Route path="device-structure" element={<DeviceStructure />} />
                </Route>
                {/* 配电管理 */}
                <Route path="distributing-management">
                  <Route index element={<Navigate to="distributing-manage" />} />
                  <Route path="distributing-manage" element={<DistributingManage />} />
                  <Route path="point-diagram" element={<PointDiagram />} />
                  <Route path="point-diagram-edit" element={<PointDiagramEdit />} />
                </Route>
                {/* 电能质量 */}
                <Route path="energy-quantity">
                  <Route index element={<Navigate to="energy-quantity-overview" />} />
                  {/* 总览 */}
                  <Route path="energy-quantity-overview" element={<EnergyQuantityOverview />}></Route>
                  {/* 详情 */}
                  <Route path="energy-quantity-detail" element={<EnergyQuantityDetail />}></Route>
                  {/* 电压偏差数据 */}
                  <Route path="energy-quantity-deviation" element={<EnergyQuantityDeviation />}></Route>
                  {/* 三相不平衡数据 */}
                  <Route path="energy-quantity-threePhaseData" element={<ThreePhaseData />}></Route>
                  {/* 频率偏差数据 */}
                  <Route path="energy-quantity-frequency" element={<FrequencyDeviationData />}></Route>
                  {/* 电能质量配置 */}
                  <Route path="energy-quantity-setting" element={<EnergyQuantitySetting />}></Route>
                </Route>
                {/* 传感器 */}
                <Route path="sensor-manage">
                  <Route index element={<Navigate to="operator-sensor" />}></Route>
                  {/* 传感器 */}
                  <Route path="operator-sensor" element={<Sensor />} />
                  {/* 实时抄表 */}
                  <Route path="real-meter" element={<RealMeter />} />
                </Route>
                <Route
                  path="regional-management" // 能耗监控 - 区域管理
                  element={<RegionalManagement />}
                />
                <Route
                  path="realtime-monitoring" // 能耗监控 - 实时监控
                  // element={<Outlet />}
                >
                  <Route
                    index
                    element={<Navigate to="realtime-overview" />} // 重定向到 能耗监控 - 实时监控 - 实时总览
                  />
                  <Route
                    path="realtime-overview" // 能耗监控 - 实时监控 - 实时总览
                    element={<RealtimeOverview />}
                  />
                  <Route
                    path="realtime-detail" // 能耗监控 - 实时监控 - 实时总览
                    element={<RealtimeDetail />}
                  />
                  <Route
                    path="data-detail" // 能耗监控 - 实时监控 - 数据详情
                    element={<DataDetail />}
                  />
                  {/* 设备总览 */}
                  <Route path="device-overview" element={<DeviceOverview />}></Route>
                  <Route path="device-detail" element={<DeviceDetail />}></Route>
                  <Route path="device-data" element={<DeviceData />}></Route>
                </Route>

                <Route
                  path="energy-consumption-statement" // 能耗监控 - 能耗报表
                  // element={<Outlet />}
                >
                  <Route
                    index
                    element={<Navigate to="energy-price-management" />} // 重定向到 能耗监控 - 能耗报表 - 能耗价格管理
                  />
                  <Route
                    path="energy-price-management" // 能耗监控 - 能耗报表 - 能耗价格管理
                    // element={<EnergyPriceManagement />}
                  >
                    <Route
                      index
                      element={<EnergyPriceManagement />} // 重定向到 能耗监控 - 能耗报表 - 能耗价格管理
                    />
                    <Route
                      path="add-base-energy-price" // 能耗监控 - 能耗报表 - 添加基础电价
                      element={<AddBaseEnergyPriceManagement />}
                    />
                    <Route
                      path="edit-base-energy-price" // 能耗监控 - 能耗报表 - 编辑基础电价
                      element={<EditBaseEnergyPriceManagement />}
                    />
                    <Route
                      path="add-usage-energy-price" // 能耗监控 - 能耗报表 - 添加用量电价
                      element={<AddUsageEnergyPriceManagement />}
                    />
                    <Route
                      path="edit-usage-energy-price" // 能耗监控 - 能耗报表 - 添加用量电价
                      element={<EditUsageEnergyPriceManagement />}
                    />
                    <Route
                      path="check-usage-energy-price" // 能耗监控 - 能耗报表 - 查看用量电价
                      element={<CheckUsageEnergyPriceManagement />}
                    />
                    <Route
                      path="add-fore-bone-price" // 能耗监控 - 能耗报表 - 添加力调电价
                      element={<AddForeBoneEnergyPrice />}
                    />
                    <Route
                      path="check-fore-bone-price" //能耗监控 - 能耗报表 - 查看力调电价
                      element={<CheckForceBoneEnergyPrice />}
                    />
                    <Route
                      path="edit-fore-bone-price" //能耗监控 - 能耗报表 - 编辑力调电价
                      element={<EditForceBoneEnergyPrice />}
                    />
                  </Route>

                  {/* <Route
                    path="add-base-energy-price-management" // 能耗监控 - 能耗报表 - 添加基础电价
                    element={<AddBaseEnergyPriceManagement />}
                  /> */}
                </Route>
              </Route>
            </Route>

            {/* 监控大屏 */}
            <Route path="/" element={<NormalLayout />}>
              <Route
                index
                element={<Navigate to="/monitoring-screen/charging-pile-screen" />} // 重定向到 能耗监控 - 区域管理
              />
              <Route
                path="monitoring-screen" // 能耗监控
                element={<EnergyMonitoringLayout parentPath="/monitoring-screen" key="monitoring-screen" />}
              >
                <Route
                  index
                  element={<Navigate to={activeMenu('/monitoring-screen')} />} // 重定向到 能耗监控 - 区域管理
                />
                <Route
                  path="comprehensive-screen" // 综合大屏
                  element={<ComprehensiveScreen />}
                />
                <Route
                  path="charging-pile-screen" // 充电桩大屏
                  element={<ChargingPileScreen />}
                />
                <Route
                  path="photovoltaic-screen" // 光伏大屏
                  element={<PhotovoltaicScreen />}
                />
                <Route
                  path="energyStorage-screen" // 储能大屏
                  element={<EnergyStorageScreen />}
                />
                {/* 能耗大屏 */}
                <Route path="energyConsume-screen" element={<EnergyConsumeScreen />}></Route>
                {/* 配电业务大屏/monitoring-screen/distributing-screen */}
                <Route path="distributing-screen" element={<DistributingScreen />}></Route>
                {/*配电业务数据*/}
                <Route path="distributing-data-manage" element={<DistributingDataManage />}></Route>
              </Route>
            </Route>
            {/* 充电桩管理 */}
            <Route path="/" element={<NormalLayout />}>
              <Route
                index
                element={<Navigate to="/charging-station/operation-management" />} // 重定向到 充电状态管理 - 运营商管理
              />
              <Route
                path="charging-station" // 能耗监控
                element={<EnergyMonitoringLayout parentPath="/charging-station" key="charging-station" />}
              >
                <Route
                  index
                  element={<Navigate to={activeMenu('/charging-station')} />} // 重定向到 充电状态管理 - 运营商管理
                />
                <Route
                  path="operation-management" // 充电桩管理 - 运营商管理
                  element={<OperationalManagement />}
                />
                <Route
                  path="charging-location" // 充电桩管理 - 充电站管理
                  element={<ChargingLocationManagement />}
                />
                <Route
                  path="price-management" // 充电桩管理 - 价格管理
                  element={<PriceManagement />}
                />
                <Route
                  path="charging-station-management" // 充电桩管理 - 充电桩管理
                  element={<ChargingStationManagement />}
                />
                <Route
                  path="charging-gun" // 充电桩管理 - 充电枪管理
                  element={<ChargingGunManagement />}
                />
                <Route
                  path="floor-lock" // 充电桩管理 - 地锁管理
                  element={<FloorlockManagement />}
                />
                <Route
                  path="charging-order" // 充电桩管理 - 订单管理
                  element={<ChargingOrderManagement />}
                />

                <Route
                  path="charging-ticket" // 充电桩管理 - 发票管理
                  element={<TicketManagement />}
                />

                <Route
                  path="electronic-card" // 充电桩管理 - 电子卡管理
                  element={<ElectronicCardManagement />}
                />
              </Route>
            </Route>
            {/* 告警管理 */}
            <Route path="/" element={<NormalLayout />}>
              <Route
                index
                element={<Navigate to="/alarm-management/energy-alarm" />} // 重定向到 充电状态管理 - 运营商管理
              />
              <Route
                path="alarm-management" // 能耗监控
                element={<EnergyMonitoringLayout parentPath="/alarm-management" key="alarm-management" />}
              >
                <Route
                  index
                  element={<Navigate to={activeMenu('/alarm-management')} />} // 重定向到 充电状态管理 - 运营商管理
                />
                <Route
                  path="energy-alarm" // 充电桩管理 - 运营商管理
                  element={<EnergyAlarm />}
                />
                <Route
                  path="photovoltaic-alarm" // 充电桩管理 - 充电站管理
                  element={<PhotovoltaicAlarm />}
                />
                <Route
                  path="storage-alarm" // 充电桩管理 - 价格管理
                  element={<StorageAlarm />}
                />
                {/* <Route
                  path="operator-station-alarm" // 充电桩管理 - 运营商充电桩告警
                  element={<OperatorStationAlarm />}
                /> */}
                <Route
                  path="station-alarm" // 充电桩管理 - 充电桩告警
                  element={userInfo.type === 1 ? <AggregationStationAlarm /> : <OperatorStationAlarm />}
                />
                <Route
                  path="worksheet-management" // 充电桩管理 - 工单管理
                  element={<WorksheetManagement />}
                />
                <Route
                  path="alarm-setting" // 充电桩管理 - 告警管理
                >
                  <Route
                    index // 充电桩管理 - 告警管理
                    element={<EnergyAlarmSetting />}
                  />
                  <Route
                    path="charge-pile-alarm-setting" // 充电桩管理 - 充电桩告警管理
                    element={
                      userInfo.type === 2 ? (
                        <AlarmSetting key="charge-pile-alarm-setting" />
                      ) : merchantId ? (
                        <AlarmSetting key="charge-pile-alarm-setting" />
                      ) : (
                        <ChargeStationAlarmList key="charge-station-alarm-list" />
                      )
                    }
                  />
                  <Route
                    path="solar-power-alarm-setting" // 充电桩管理 - 光伏告警管理
                    element={
                      userInfo.type === 2 ? (
                        <AlarmSetting key="solar-power-alarm-setting" />
                      ) : cpvMerchantId ? (
                        <AlarmSetting key="solar-power-alarm-setting" />
                      ) : (
                        <ChargeStationAlarmList key="solar-power-alarm-setting" />
                      )
                    }
                  />
                  <Route path="energy-alarm-operator" element={<EnergyAlarmOperator />}></Route>
                  <Route path="energy-alarm-setting" element={<EnergyAlarmSetting />}></Route>
                </Route>
                <Route
                  path="alarm-list" // 充电桩管理 - 电子卡管理
                >
                  <Route
                    index // 充电桩管理 - 电子卡管理
                    element={<ChargeStationAlarmList />}
                  />

                  <Route
                    path="photovoltaic-alarm-list" // 光伏告警
                    element={<ChargeStationAlarmList key="photovoltaic-alarm-list" />}
                  />
                </Route>
              </Route>
            </Route>
            {/* 数据分析 */}
            <Route path="/" element={<NormalLayout />}>
              <Route
                index
                element={<Navigate to="/data-analysis/data-energy" />} // 重定向到 数据分析 - 能耗分析
              ></Route>
              <Route
                path="data-analysis" // 数据分析
                element={<EnergyMonitoringLayout parentPath="/data-analysis" key="data-analysis" />}
              >
                <Route
                  index
                  element={<Navigate to={activeMenu('/data-analysis')} />} // 重定向到 数据分析 - 能耗分析
                />
                {/* 能耗分析 */}
                <Route path="data-energy">
                  <Route index element={<Navigate to="/data-analysis/data-energy/energy-analysis" />} />
                  {/* 数据分析 */}
                  <Route path="energy-analysis" element={<EnergyAnalysis />} />
                  {/* 用能分析 */}
                  <Route path="usage-analysis" element={<UsageAnalysis />}></Route>
                  {/* 同比分析 */}
                  <Route path="yoy-analysis" element={<YOYAnalysis />}></Route>
                  {/* 环比分析 */}
                  <Route path="qoq-analysis" element={<QOQAnalysis />} />
                  {/* 用能趋势 */}
                  <Route path="usage-trend" element={<UsageTrend />}></Route>
                  {/* 用能排名 */}
                  <Route path="usage-ranking" element={<UsageRanking />}></Route>
                  {/* 数据分析-费用报表-二氧化碳排放量 */}
                  <Route path="energy-report" element={<EnergyReport />}></Route>
                  {/* 集中抄表 */}
                  <Route path="centralized-meter-reading" element={<CentralizedMeterReading />}></Route>
                  {/* 损耗分析 */}
                  <Route path="loss-analysis" element={<LossAnalysis />}></Route>
                  {/* 自定义对比 */}
                  <Route path="compare-analysis" element={<CompareAnalysis />}></Route>
                </Route>
                {/* 光伏分析 */}
                <Route
                  path="photovoltaic-analysis" // 数据分析 - 光伏分析
                >
                  <Route
                    index
                    element={<Navigate to="/data-analysis/photovoltaic-analysis/power-station-report" />} // 重定向到 数据分析 - 能耗分析
                  />
                  <Route
                    path="power-station-report" // 数据分析 - 能耗分析
                    element={<PowerStationReport />}
                  />
                  <Route
                    path="inverter-report" // 数据分析 - 能耗分析
                    element={<InverterStationReport />}
                  />
                </Route>
                <Route
                  path="storage-analysis" // 数据分析 - 储能分析
                >
                  <Route index element={<Navigate to="/data-analysis/storage-analysis/photovoltaic-report" />} />
                  <Route
                    path="photovoltaic-report" // 数据分析 - 光伏报表
                    element={<PhotovoltaicReport />}
                  />
                  <Route
                    path="storage-report" // 数据分析 - 储能报表
                    element={<StorageReport />}
                  />
                </Route>
                {/* <Route
                  path="storage-analysis" // 数据分析 - 储能分析
                  element={<StorageAnalysis />}
                /> */}
                <Route
                  path="charging-station-analysis" // 数据分析 - 充电桩分析
                  element={<ChargingStationAnalysis />}
                />
              </Route>
            </Route>
            {/* 首页 */}
            <Route path="/" element={<NormalLayout />}>
              <Route
                index
                element={<Navigate to="/dashboard/energy-dashboard" />} // 重定向到 数据分析 - 能耗分析
              />
              <Route
                path="dashboard" // 数据分析
                element={<EnergyMonitoringLayout parentPath="/dashboard" key="dashboard" />}
              >
                <Route
                  index
                  element={<Navigate to={activeMenu('/dashboard')} />} // 重定向到 数据分析 - 能耗分析
                />
                <Route
                  path="energy-dashboard" // 数据分析 - 能耗分析
                  element={<EnergyDashboard />}
                />
                <Route
                  path="photovoltaic-dashboard" // 数据分析 - 光伏分析
                  element={<PhotovoltaicDashboard />}
                />
                <Route
                  path="storage-dashboard" // 数据分析 - 储能分析
                  element={<StorageDashboard />}
                />
                <Route
                  path="charging-station-dashboard" // 数据分析 - 充电桩分析
                  element={<ChargingStationDashboard />}
                />
              </Route>
            </Route>
            {/* 系统设置 */}
            <Route path="/" element={<NormalLayout />}>
              <Route index element={<Navigate to="/system-setting/user-management" />} />
              <Route
                path="system-setting"
                element={<EnergyMonitoringLayout parentPath="/system-setting" key="system-setting" />}
              >
                <Route index element={<Navigate to={activeMenu('/system-setting')} />} />
                <Route path="user-management" element={<UserManage />} />
                <Route path="role-management" element={<RoleManage />} />
                <Route path="menu-management" element={<MenuManage />} />
                <Route path="operation-management" element={<OperationManagement />} />
              </Route>
            </Route>
            {/* 光伏管理 */}
            <Route path="/" element={<NormalLayout />}>
              {/* <Route index element={<Navigate to="/photovoltaic-management/operator-manage" />} /> */}
              <Route
                path="photovoltaic-management"
                element={<EnergyMonitoringLayout parentPath="/photovoltaic-management" key="photovoltaic-management" />}
              >
                <Route index element={<Navigate to={activeMenu('/photovoltaic-management')} />} />
                {/* 运营商管理 */}
                <Route path="operator-manage" element={<PhotovoltaicOperatorManagement />} />
                {/* 电站管理 */}
                <Route path="power-station-manage" element={<PowerStationManagement />} />
                {/* 设备管理 */}
                <Route path="photovoltaic-device-manage" element={<DeviceManagement />} />
                {/* 电站状态 */}
                <Route path="power-station-status" element={<AggregatorPowerStationStatus />} />
              </Route>
            </Route>
            {/* 储能管理 */}
            <Route path="/" element={<NormalLayout />}>
              {/* <Route index element={<Navigate to="/energyStorage-management/operator-manage" />} /> */}
              <Route
                path="energyStorage-management"
                element={
                  <EnergyMonitoringLayout parentPath="/energyStorage-management" key="energyStorage-management" />
                }
              >
                <Route index element={<Navigate to={activeMenu('/energyStorage-management')} />} />

                {/* 电站管理 */}
                <Route path="power-station-manage" element={<EnergyPowerStationManagement />} />

                {/* 电站状态 */}
                <Route path="power-station-status" element={<EnergyPowerStationStatus />} />
              </Route>
            </Route>
            {/* 登录 */}
            <Route path="/user/login" element={<Login />}></Route>

            {/* 传感器设置 */}
            <Route path="/sensor-settings" element={<SensorSettings />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
