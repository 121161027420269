import { ElectricPriceBaseRecordItem } from '@/services/energyMonitoring/energyPriceManagement/type';
import { SensorType } from '@/services/realtimeDetail/type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BaseEnergyPriceState {
  baseEnergyPriceData?: any;
  selectedSensorType: SensorType;
}

export const baseEnergyPriceSlice = createSlice({
  name: 'baseEnergyPrice',
  initialState: {
    baseEnergyPriceData: {},
    selectedSensorType: SensorType.ELECTRICITY,
  } as BaseEnergyPriceState,
  reducers: {
    updateBaseEnergyPriceData: (state, action: PayloadAction<{ baseEnergyPriceData: ElectricPriceBaseRecordItem; }>) => {
      state.baseEnergyPriceData = action.payload;
    },
    updateSensorType: (state, action: PayloadAction<SensorType>) => {
      state.selectedSensorType = action.payload;
    },

  }
});

// Action creators are generated for each case reducer function
export const { updateBaseEnergyPriceData, updateSensorType } = baseEnergyPriceSlice.actions;

export default baseEnergyPriceSlice.reducer;
