import { get, post } from '@/utils/requestUtils';
import { DEFAULT_API_PREFIX } from '@/constants/apiPrefixes';
import { FetchUserInfoResp, LoginRequest } from './type';

export async function randomImage(params: { time: number }) {
  return get<string>(`sys/randomImage/${params.time}?_t=${Math.floor(params.time / 1000)}`, {
    prefix: DEFAULT_API_PREFIX
  });
}

export async function login(params: LoginRequest) {
  return post<{ token: string }>('login/doLogin', {
    data: params,
    prefix: DEFAULT_API_PREFIX
  });
}

export async function fetchUserInfo() {
  return post<FetchUserInfoResp>('myLogin/getUserInfo', {
    prefix: DEFAULT_API_PREFIX
  });
}
