import React, { useCallback, useState } from 'react';
import css from './Login.module.scss';
import { Button, ConfigProvider, Form, Input } from 'antd';
import { ReactComponent as logo } from './logo.svg';
import Icon from '@ant-design/icons';
import { ReactComponent as userSVG } from '@/icons/border/user.svg';
import { ReactComponent as lockSVG } from '@/icons/border/lock.svg';
import { ReactComponent as safeSVG } from '@/icons/border/safe.svg';
import colorVariables from '@/styles/colors.scss';
import { randomImage, login } from '@/services/user/user.service';
import { LoginRequest } from '@/services/user/type';
import { useRequest } from 'ahooks';
import { updateUserInfo } from '@/redux/systemSlice';
import { useAppDispatch } from '@/hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import useBackLink from '@/hooks/useBackLink';
import { getUserInfo } from '@/services/systemSetting/systemSetting.service';
import { getList } from '@/services/chargingStation/OperationManage/OperationManage.service';
import { IndustryType } from '@/services/systemSetting/type';
import { QueryListType } from '@/services/chargingStation/OperationManage/type';

const Login = React.memo(() => {
  const navigate = useNavigate();

  const { resolveBackLink } = useBackLink();

  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const [time, setTime] = useState<number>(new Date().getTime());

  const randomImageRequest = useRequest((value = time) => randomImage({ time: value }));
  const loginRequest = useRequest((data: LoginRequest) => login(data), { manual: true });
  const fetchUserInfoRequest = useRequest(getUserInfo, { manual: true });

  // 获取运营商
  const operatorListReques = useRequest(() => getList({ pageNo: 1, pageSize: 1000, industry: IndustryType.NENGHAO }), {
    manual: true
  });
  const onFinish = async (values: LoginRequest) => {
    try {
      const data = await loginRequest.runAsync({
        ...values,
        checkKey: time
      });

      localStorage.setItem('token', data?.result.token);

      const userInfoResult = await fetchUserInfoRequest.runAsync();
      const operatorList = await operatorListReques.runAsync();

      if (userInfoResult.result.merchantId) dispatch(updateUserInfo(userInfoResult.result));
      else {
        // 当聚合上商登录时默认选中第一个聚合商
        dispatch(
          updateUserInfo({
            ...userInfoResult.result,
            merchantId: (operatorList?.result.records as QueryListType[])?.[0].id
          })
        );
      }

      const backLink = resolveBackLink();
      if (backLink) {
        navigate(`${backLink}`);
      } else {
        navigate('/monitoring-screen');
      }
    } catch (e) {
      reloadCaptcha();
    }
  };

  const reloadCaptcha = useCallback(() => {
    form.setFieldsValue({ captcha: '' });
    setTime(new Date().getTime());
    randomImageRequest.run(new Date().getTime());
  }, [form, randomImageRequest]);

  return (
    <div className={css.Login}>
      <div className={css.loginForm}>
        <div style={{ marginTop: 56 }}>
          <Icon style={{ fontSize: 56 }} component={logo} />
        </div>
        <span className={css.txt}>用户登录 / USER LOGIN</span>
        <div className={css.line}></div>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorText: colorVariables.whiteTitle,
                colorBgContainer: colorVariables.whiteCard,
                colorBorder: colorVariables.whiteBorder,
                controlHeight: 38.9
              }
            }
          }}
        >
          <Form
            form={form}
            name="basic"
            layout="vertical"
            style={{ width: 339 }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="用户账号"
              colon={false}
              name="username"
              rules={[{ required: true, message: '请输入账号！' }]}
            >
              <Input prefix={<Icon style={{ fontSize: 14 }} component={userSVG} />} placeholder="请输入账号" />
            </Form.Item>

            <Form.Item
              label="登录密码"
              colon={false}
              name="password"
              rules={[{ required: true, message: '请输入密码！' }]}
            >
              <Input.Password prefix={<Icon style={{ fontSize: 14 }} component={lockSVG} />} placeholder="请输入密码" />
            </Form.Item>

            <Form.Item>
              <Form.Item
                label="验证码"
                colon={false}
                name="captcha"
                rules={[{ required: true, message: '请输入验证码！' }]}
                style={{ display: 'inline-block' }}
              >
                <Input
                  style={{ width: '247px' }}
                  prefix={<Icon style={{ fontSize: 14 }} component={safeSVG} />}
                  placeholder="请输入验证码"
                />
              </Form.Item>
              <Form.Item label=" " style={{ display: 'inline-block' }}>
                <img width={76} height={38} src={randomImageRequest.data?.result} onClick={reloadCaptcha} />
              </Form.Item>
            </Form.Item>
            <Form.Item>
              <Button
                style={{ width: '339px', height: '40px', position: 'relative', top: '-24px' }}
                type="primary"
                htmlType="submit"
              >
                登录
              </Button>
            </Form.Item>
          </Form>
        </ConfigProvider>
      </div>
    </div>
  );
});

export default Login;
