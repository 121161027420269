/* eslint-disable no-param-reassign */
import { DEFAULT_API_PREFIX } from '@/constants/apiPrefixes';
import { message } from 'antd';
import axios, { CancelToken, Method } from 'axios';
import qs from 'qs';

const axiosInstance = axios.create();

export interface GetRequestOptions {
  headers?: any;
  pathParams?: Record<string, string | number>;
  query?: Record<string, any>;
  prefix?: string;
  withCredentials?: boolean;
  cancelToken?: CancelToken;
  xsrfCookieName?: string;
  xsrfHeaderName?: string;
  responseType?: XMLHttpRequestResponseType;
}

export interface RequestOptions extends GetRequestOptions {
  data?: Record<any, any>;
}

export interface GenericRequestOptions extends RequestOptions {
  method?: Method;
}

// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    const { data } = response;
    if (`${data?.code}` === '401') {
      localStorage.removeItem('token'); // 清理本地缓存
      if (window.location.pathname !== '/user/login') {
        message.error(data?.message ?? '');
      }
      return Promise.reject(data);
    }
    // 处理文件流
    if (response.status === 200 && response.config.headers.responseType === 'blob') {
      return Promise.resolve(data);
    }

    if ((`${data?.code}` !== '200' && data?.code !== 0) || !data?.success) {
      message.error(data?.message ?? '');
      return Promise.reject(data);
    }
    // if success
    //没有数据的response没有data.data
    if (response.data?.result || response.data) {
      return { ...response.data, data: response.data?.result };
    }
    // if `result` property not exists, reject
    return Promise.resolve(null);
  },
  error => {
    if (error.response && error.response.status) {
      message.error(error.response?.data?.message || error.message || undefined);
      if (error.response.status === 401 || error.response.status === 403) {
        localStorage.removeItem('token');
        if (location.pathname !== '/user/login') {
          window.location.href = `/user/login?back=${location.pathname}`;
        }
      }
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error.response || error);
  }
);

// export function request<T>(
//   urlTemplate: string,
//   options: GenericRequestOptions = {}
// ): Promise<T> {
//   return axiosInstance.request({
//     url: SafeUrlAssembler()
//       .template(urlTemplate)
//       .param(options.pathParams || {})
//       .toString(),
//     params: {
//       ...options.query,
//     },
//     paramsSerializer: (params: any) => {
//       return qs.stringify(params, { indices: false });
//     },
//     baseURL: options.prefix || DEFAULT_API_PREFIX,
//     data: options.data,
//     // by default, we send credentials
//     withCredentials:
//       typeof options.withCredentials === "boolean"
//         ? options.withCredentials
//         : true,
//     method: options.method,
//     headers: options.headers,
//     cancelToken: options.cancelToken,
//     xsrfCookieName: options.xsrfCookieName,
//     xsrfHeaderName: options.xsrfHeaderName,
//   });
// }

export function request<T>(urlTemplate: string, options: GenericRequestOptions = {}): Promise<Response<T>> {
  const token = window.localStorage.getItem('token');

  const requestOptions = {
    url: urlTemplate,
    params: {
      ...options.query
    },
    paramsSerializer: {
      serialize: (params: any) => {
        return qs.stringify(params, { indices: false });
      }
    },
    baseURL: options.prefix || DEFAULT_API_PREFIX,
    data: options.data,
    // by default, we send credentials
    withCredentials: typeof options.withCredentials === 'boolean' ? options.withCredentials : true,
    method: options.method,
    headers: {
      ...options.headers,
      'X-Access-Token': token
    },
    cancelToken: options.cancelToken,
    xsrfCookieName: options.xsrfCookieName,
    xsrfHeaderName: options.xsrfHeaderName,
    responseType: options.responseType || 'json'
  };

  return axiosInstance.request(requestOptions);
}

export interface Response<T> {
  success: boolean;
  message: string;
  code: number;
  result: T;
  data: T;
  timestamp: number;
}

export function get<T = any>(urlTemplate: string, options: GetRequestOptions = {}): Promise<Response<T>> {
  return request(urlTemplate, {
    ...options,
    method: 'GET'
  });
}

export function post<T = any>(urlTemplate: string, options: RequestOptions = {}): Promise<Response<T>> {
  return request(urlTemplate, {
    ...options,
    method: 'POST'
  });
}

export function put<T = any>(urlTemplate: string, options: RequestOptions = {}): Promise<Response<T>> {
  return request(urlTemplate, {
    ...options,
    method: 'PUT'
  });
}

export function patch<T = any>(urlTemplate: string, options: RequestOptions = {}): Promise<Response<T>> {
  return request(urlTemplate, {
    ...options,
    method: 'PATCH'
  });
}

export function delet<T = any>(urlTemplate: string, options: RequestOptions = {}): Promise<Response<T>> {
  return request(urlTemplate, {
    ...options,
    method: 'DELETE'
  });
}
