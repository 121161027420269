import { AreaType, SensorType } from '@/services/realtimeDetail/type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RealtimeDetailState {
  selectedAreaId?: string;
  selectedAreaType?: AreaType;
  selectedSensorType: SensorType;
  hasChildren: boolean; // 是否有下级
  defaultSelectedNodeKey: string;
}

export const realtimeDetailSlice = createSlice({
  name: 'realtimeDetail',
  initialState: {
    selectedAreaId: undefined,
    selectedAreaType: undefined,
    hasChildren: false, // 是否有下级
    selectedSensorType: SensorType.ELECTRICITY,
    defaultSelectedNodeKey: '',
  } as RealtimeDetailState,
  reducers: {
    updateSelectedArea: (state, action: PayloadAction<{ selectedAreaId: string; selectedAreaType: AreaType }>) => {
      state.selectedAreaId = action.payload.selectedAreaId;
      state.selectedAreaType = action.payload.selectedAreaType;
    },
    updateSensorType: (state, action: PayloadAction<SensorType>) => {
      state.selectedSensorType = action.payload;
    },
    updateHasChildren: (state, action: PayloadAction<boolean>) => {
      state.hasChildren = action.payload;
    },
    updateDefaultSelectedNodeKey: (state,action:PayloadAction<string>) => {
      state.defaultSelectedNodeKey = action.payload;
    },
  }
});

// Action creators are generated for each case reducer function
export const { updateSelectedArea, updateSensorType, updateHasChildren,updateDefaultSelectedNodeKey } = realtimeDetailSlice.actions;

export default realtimeDetailSlice.reducer;
